.product {
  margin-bottom: 5px;
  .one-column {
    display: flex;
    flex-direction: column;    
    text-align: center;
    max-width: 90%;
    margin: 5px auto;
    @include media("<=425px") {
    }
    .headline {
      background-image: linear-gradient(to right, #dadada , #d4d4d4);
      width: 100%;
      font-size: 56px;
      font-weight: 700;
      padding-top: 50px;
      padding-bottom: 10px;
      @include media("<=425px") {
        font-size: 32px;
      }
    }
    .productPhoto {
      @include media("<=425px") {
        height: 300px;
      }
    }
    .text {
      background-image: linear-gradient(to right, #dadada , #d4d4d4);
      font-weight: 200;
      line-height: 1.2;
      font-size: 24px;
      text-align: justify;
      padding: 0 20%;
      .bold {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.2;
      }
      @include media("<=425px") {
        padding: 0 7%;
      }
    }
  }
}