.notFound {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 600px;
    line-height: 1.5;
    @include media(">1440px") {
        height: 1000px;
    }
    h1 {
        font-size: 70px;
        margin-top: auto;
        font-weight: 600; 
    }
    p {
        font-size: 20px;
        line-height: 2;
    }
    .notFoundButton {
        margin-bottom: auto;
        font-size: 15px;
        text-decoration: none;
        color: black;
        font-weight: 300;
        &:hover {
            text-decoration: underline;
        }
    }
}