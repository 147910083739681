.grid {
  margin-bottom: 5px;
  .one-column {
    display: flex;
    flex-wrap: wrap;  
    justify-content: center;
    text-align: center;
    max-width: 90%;
    background-color: $bamboo;
    padding: 25px 50px;
    margin: 5px auto;
    @include media("<=425px") {
      padding: 25px 0;
    }
    .bamboo {
      width: 30%;
      margin-top: 10px;
      @include media("<=425px") {
        width: 70%;
      }
      @include media(">425px", "<=1024px") {
        width: 40%;
      }
      @include media(">1440px") {
        width: 20%;
      }
    }
    .headline {
      width: 100%;
      font-size: 56px;
      font-weight: 700;
      margin-bottom: 10px;
      @include media("<=425px") {
        font-size: 37px;
      }
    }
    .text {
      font-size: 24px;
      font-weight: 200;
      text-align: justify;
      padding: 0 20%;
    }
  }

  .two-column {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: auto auto;
    .column-left {
      width: 50%;
      justify-content: center;
      text-align: center;
      background-color: $white;
      padding: 25px 25px;
      @include media("<=425px") {
        width: 100%;
      }
      .headline {
        font-size: 56px;
        font-weight: 700;
        margin-bottom: 10px;
        @include media("<=425px") {
          font-size: 37px;
        }
       }
      .text {
        font-size: 24px;
        font-weight: 200;
        text-align: justify;
        padding: 0 10%;
      }
      .wood {
        max-width: 70%;
        @include media("<=425px") {
          max-width: 75%;
          margin-top: 20px;
        }
        @include media(">425px","<=768px") {
          max-width: 80%;
          margin-top: 100px;
        }
      }
    } 
    .column-right {
      width: 50%;
      justify-content: center;
      text-align: center;
      background-color: $grass;
      padding: 25px 25px;
      @include media("<=425px") {
        width: 100%;
      }
      .headline {
        font-size: 56px;
        font-weight: 700;
        margin-bottom: 10px;
        @include media("<=425px") {
          font-size: 37px;
        }
      }
      .text {
        font-size: 24px;  
        font-weight: 200;
        text-align: justify;
        padding: 0 10%;
      }
      .grass {
        max-width: 70%;
        margin-top: 30px;
        @include media("<=425px") {
          max-width: 90%;
        }
        @include media(">425px","<=768px") {
          max-width: 95%;
          margin-top: 120px;
        }
      }
    }
  }
}   