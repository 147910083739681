.imprintText {
    height: 600px;
    @include media(">1440px") {
        height: 1000px;
    }
    .imprintButton {
        display: flex;
        justify-content: center;
        font-weight: 400;
        text-decoration: none;
        color: black;
        &:hover {
            text-decoration: underline;
        }
    }
    text-align: justify;
    padding: 50px 50px;
    font-size: 15px;
    line-height: 25px;
    h1 {
        font-size: 25px;
        font-weight: 400;
        line-height: 35px;
    }
}