.final {
    flex-direction: column;
    a {
        text-decoration: none;
        font-weight: 300;
        margin-top: 25px;
    }
    .finalTextUp {
        margin-bottom: 3%;
        text-align: center;
        font-size: 2.4rem;
        font-weight: 300;
    }
    .finalTextDown {
        font-size: 8.4rem;
        font-weight: 800;
        @include media("<=425px") {
            padding: 10px 0;
            font-size: 6.4rem;
            text-align: center;
          }
        @include media(">425px", ">=768px") {
            text-align: center;
        }
    }
    .btn {
        line-height: 50px;
        margin-top: 3%;
        height: 50px;
        text-align: center;
        width: 250px;
        cursor: pointer;
        color: #000;
        transition: all 0.3s;
        position: relative;
        span {
            transition: all 0.3s;
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            transition: all 0.3s;
            border-top-width: 1px;
            border-bottom-width: 1px;
            border-top-style: solid;
            border-bottom-style: solid;
            border-top-color: #000;
            border-bottom-color: #000;
            transform: scale(0.1, 1);
        }
        &:hover {
            span {
                letter-spacing: 2px;
            }
        }
        &:hover::before {
            opacity: 1; 
            transform: scale(1, 1);
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            transition: all 0.3s;
            //background-color: rgba(71, 70, 70, 0.055);
            border-color: black;
            border-width: thin;
            border-style: solid;
        }
        &:hover::after {
            opacity: 0; 
            transform: scale(0.1, 1);
        }
    }     
}