body.loading {
  overflow: hidden;
  height: 100vh;
}

.loader {
  .loader-inner {
    .image-block {
      position: absolute;
      transform-origin: center;
      display: flex;
      align-items: center;
      justify-content: center;
      &.image-1 {
        width: 400px;
        left: 16%;
        bottom: 14%;
        @include media("<=480px") {
          left: 4%;
          bottom: 28%;
        }
      }
      &.image-3 {
        width: 300px;
        right: 12%;
        top: 8%;
        @include media("<=480px") {
          top: 16%;
          right: 4%;
        }
      }
      &.image-4 {
        max-width: 400px;
        width: 40%;
        right: 20%;
        bottom: 10%;
        @include media("<=480px") {
          right: 6%;
          bottom: 32%;
        }
      }
      &.image-5 {
        width: 280px;
        left: 14%;
        top: 12%;
        @include media("<=480px") {
          left: 6%;
          top: 18%;
        }
      }
      img {
        width: 100%;
        object-fit: fill;
      }
    }
  }
}
