.banner {
  z-index: 100;
  position: relative;
  font-weight: 900;
  .banner-row {
    overflow: hidden;
    display: flex;
    align-items: center;
    }
    &.center {
      justify-content: center;
      text-align: center;
      .row-letter {
        left: 0;
      }
    }
    &.right {
      justify-content: flex-end;
    }
    .row-title,
    .row-letter {
      font-size: 28rem;
      font-weight: 500;
      position: relative;
      letter-spacing: -0.8rem;
      display: inline-block;
      white-space: nowrap;
      @include media("<=480px") {
        font-size: 9rem;
        height: 100px;
      };
      @include media(">480px", "<=tablet") {
        font-size: 14rem;
        height: 160px;
      };
      @include media(">tablet","<=1100px") {
        font-size: 18rem;
        height: 203px;
      }; 
      @include media(">1100px","<=1440px") {
        font-size: 24rem;
        height: 270px;
      };
      @include media(">1440px", "<LGdesktop") {
        height: 315px;
      };
      @include media(">LGdesktop", "<2200px") {
        font-size: 40rem;
        height: 450px;
      };  
      @include media(">2200px") {
        font-size: 52rem;
        height: 587px;
      };  
    }
    .row-col {
      width: 70%;
      display: flex;
      align-content: center;
      justify-content: center;
      @include media("<=480px") {
        width: 100%;
        justify-content: flex-start;
      }
      @include media("<=768px") {
        width: 64%;
      }
      @include media(">768px","<=1024px") {
        width: 63%;
      }
      @include media(">1024px","<=1440px") {
        width: 62%;
      }
      @include media(">=2200px") {
        width: 48%;
      }
      .row-message {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.6rem;
        width: 320px;
      }
    }
    .scroll {
      height: 160px;
      width: 160px;
      border-radius: 100%;
      background: $white;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      left: 160px;
      @include media("<=1440px") {
        left: 60px;
      }
      @media (max-width: 1240px) {
        display: none;
      }
      span {
        font-size: 18px;
        font-weight: 600;
        margin: 4px 0;
        @include media("<=480px") {
          font-size: 10px;
          margin: 2px 0;
        }
      }
    }
  }

.transition-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 82vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 800px;
    display: flex;
  }
  &.final {
    display: block;
    top: -128px;
    position: relative;
    width: 90%;
    margin: 0 auto;
    z-index: -100;
    @include media("<=480px") {
      top: -56px;
      height: 50vw;
    }
    @include media(">480px","<=768px") {
      height: 44vw;
    }
    @include media(">768px","<=1024px") {
      height: 48vw;
    }
    @include media(">1024px","<=1440px") {
      height: 52vw;
    }
    @include media(">1440px") {
      height: 56vw;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
