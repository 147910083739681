.footer {
    margin-top: auto;
    border-top: 1px solid #000;
    width: 100%;
    padding: 10px 30px;
    display: flex;
    @include media("<=425px") {
        margin-top: 0;
      }
    .footerContent {
        font-weight: 300;
        width: 33%;
        margin: auto;
    }
    .imprint {
        font-size: 1.4rem;
        font-weight: 300;
        text-align: center;
        margin: auto;
        color: black;
        width: 33%;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .iconList {
        width: 33%;
        display: flex;
        margin: auto;
        flex-direction: row-reverse;
        .insta {
            height: 25px;
        }

    }
}