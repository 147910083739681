.header {
  font-size: 1.8rem;
  height: 156px;
  display: flex;
  justify-content: center;
  padding: 0 108px;
  color: $black;
  @include media(">tablet", "<=desktop") {
    padding: 0 72px;
    font-size: 1.6rem;
  }
  @include media("<=480px") {
    padding: 0 12px;
    height: 96px;
    font-size: 1.2rem;
  }
  .header-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 120px;
      @include media("<=1440px") {
        font-size: 3.2rem;
        flex: 0 1;
      }
    }
    .nav {
      @include media("<=1440px") {
        display: none;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      li {
        list-style: none;
        margin: 0 40px;
        a {
          text-decoration: none;
          color: $black;
          white-space: nowrap;
        }
      }
    }
    .contact {
      @include media("<=1440px") {
        flex: 0 1;
      }
      a {
        color: $black;
        text-decoration: none;
        border-bottom: 2px solid $black;
        padding-bottom: 12px;
        white-space: nowrap;
        @include media("<=480px") {
          border: none;
        }
      }
    }
  }
}
